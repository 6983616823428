import * as R from "ramda"
import React from "react"

function importAll(r) {
  let images = {}
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item)
  })
  return images
}

const images = importAll(
  require.context("../assets/imgs/halloween", false, /\.(png|jpg|webp)$/)
)

const RowsGen = (times) => {
  let x, y
  let rows = []
  let nums = []

  for (x = 1; x < times; x++) {
    let cols = []
    for (y = 1; y < times; y++) {
      let n = Math.floor(Math.random() * 156) + 1 // generated number
      if (R.not(R.includes(n, nums))) {
        nums.push(n)
        cols.push(
          <div
            className="squares--box d-flex align-items-center justify-content-center"
            key={y}
          >
            <img src={Object.entries(images)[n][1]} />
          </div>
        )
      } else {
        n = Math.floor(Math.random() * 49) + 1 // generated number
        nums.push(n)
        cols.push(
          <div
            className="squares--box d-flex align-items-center justify-content-center"
            key={y}
          >
            <img src={images[`${n}.jpg`]} />
          </div>
        )
      }
    }
    rows.push(
      <div className="row squares--row" key={x}>
        {cols}
      </div>
    )
  }
  return rows
}

const RenderCards = (times) => {
  let z
  let card = []
  for (z = 0; z < times; z++) {
    card.push(
      <div
        className="row justify-content-center halloween-card-generated"
        key={z}
      >
        <div className="col-12 halloween-card d-flex flex-column justify-content-center">
          <div className="halloween-card-container">
            <header className="halloween-card--header">
              <h1 className="title">
                Halloween <span>R & R</span>
              </h1>
            </header>
            <div className="halloween-card--body">
              <div className="row justify-content-center">
                <div className="squares">{RowsGen(5)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return card
}

const BingoCard = ({ cardCount, renderCards }) => {
  return (
    renderCards && (
      <>
        <div className="row justify-content-center print-screen-hide">
          <button onClick={() => window.print()} className="btn--print">
            Ready to Print
          </button>
        </div>
        {RenderCards(cardCount)}
      </>
    )
  )
}

export default BingoCard
